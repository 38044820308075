<!-- 设备管理-保养管理-保养工单-快速工单 -->
<template>
    <div class="page_container" v-loading="pageLoad">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">设备信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_20 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="设备编号：">
                    <el-input v-model="formInline.deviceID" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备名称：" class=" ">
                    <el-input v-model="formInline.deviceName" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备类型：" class=" ">
                    <el-input v-model="formInline.deviceTypeName" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="设备型号：">
                    <el-input v-model="formInline.deviceModel" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="所属部门：" class=" ">
                    <el-input v-model="formInline.deviceDepartment" placeholder="" class="width_180"
                        disabled></el-input>
                </el-form-item>
                <el-form-item label="设备位置：" class=" ">
                    <el-input v-model="formInline.deviceLocation" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item class=" ">
                    <el-button type="primary" class="width_120 mg_left_8" style="background: #03CABE; border: none;"
                        @click="changeDevice">
                        选择设备</el-button>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100">基本信息</div>
            <el-form :inline="true" class="mg_top_20 flex flex_wrap" :label-width="formLabelWidth" style="width: 65%;">
                <el-form-item label="保养类型：">
                    <el-select v-model="formInline.execType" placeholder="请选择" class="width_180">
                        <el-option v-for="item in maintainCategory" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保养等级：">
                    <el-select v-model="formInline.execLevel" placeholder="请选择" class="width_180">
                        <el-option v-for="item in maintainLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="紧急程度：">
                    <el-select v-model="formInline.urgenLevel" placeholder="请选择" class="width_180">
                        <el-option v-for="item in maintainUrgenLevel" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否停机：" class="">
                    <el-checkbox v-model="formInline.machineStop" class="width_180 text_align_left"></el-checkbox>
                </el-form-item>
                <el-form-item label="停机时间(分钟)：" class=" " v-if="formInline.machineStop">
                    <el-input v-model="formInline.stopLen" type="number" placeholder="" class="width_180" oninput="if(value<0){value=0}">
                    </el-input>
                </el-form-item>
                <el-form-item label="指定接单时间：" class=" ">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date"
                        v-model="formInline.assignTakeTime" type="datetime" :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="指定结单日期：" class=" ">
                    <el-date-picker value-format="timestamp" prefix-icon="el-icon-date"
                        v-model="formInline.assignEndTime" type="datetime" :clearable="false" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="领用总价：" class=" ">
                    <el-input v-model="formInline.totalPrice" type="number" placeholder="" class="width_180" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="是否审批：" class="">
                    <el-radio-group v-model="formInline.isAuth" class="width_180 text_align_left">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" :label-width="formLabelWidth" style="width:65%;" class="mg_bottom_20">
                <el-form-item label="备注：" prop="">
                    <el-input type="textarea" :rows="3" v-model="formInline.remarks" resize="none"></el-input>
                </el-form-item>
                <el-form-item label="现场情况：" class=" ">
                    <el-input type="textarea" :rows="3" v-model="formInline.spotCondition" placeholder="">
                    </el-input>
                </el-form-item>
                <el-form-item label="保养描述：" class=" ">
                    <el-input type="textarea" :rows="3" v-model="formInline.execDesc" placeholder="">
                    </el-input>
                </el-form-item>
            </el-form>
            <div class="box_head1 width_100">更换备件</div>
            <div class="flex flex_column width_100 pdl30 pdr30 height_370">
                <div class="flex align_center mg_top_20">
                    <el-button type="primary" class="width_140 mg_left_10" style=" background: #03CABE;border: none;"
                        @click="selectSpare">
                        选择备件</el-button>
                </div>
                <el-table :data="tableData" style="width: 100%" class="mg_top_20" height="100%">
                    <el-table-column type="index" label="序号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" width="">
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="规格型号" width="">
                    </el-table-column>
                    <el-table-column prop="unit" label="计量单位" width="">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价" width="">
                    </el-table-column>
                    <el-table-column prop="applyQuan" label="领用数量" width="">
                    </el-table-column>
                    <el-table-column prop="totalPrice" label="总价" width="">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width="">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="delTable(scope.$index)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex mg_top_20 mg_bottom_20">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>

            <el-dialog title="设备选择" :visible.sync="dialogTableVisible" top='10vh' width="40%">
                <div class="flex flex_column height_370 mg_top_15">
                    <div class="flex">
                        <el-cascader size="medium" v-model="organizationValue" placeholder="请选择" :options="organization"
                            ref="demoCascader" :props="defaultProps" class="el_cascader_width "
                            @change="handleChangeCascader" :show-all-levels="false" collapse-tags filterable>
                        </el-cascader>
                        <el-button type="primary" class="mg_left_10" size="medium" @click="queryDevice">查询</el-button>
                    </div>
                    <el-table :data="equInfoList" style="width: 100%" class="mg_top_20" height="100%" v-loading="load">
                        <el-table-column type="index" label="序号" width="80">
                        </el-table-column>
                        <el-table-column prop="EquipmentID" label="设备编号" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentName" label="设备名称" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentModel" label="规格型号" width="">
                        </el-table-column>
                        <el-table-column prop="EquipmentTypeName" label="设备类型" width="">
                        </el-table-column>
                        <el-table-column prop="WorkshopSection" label="所属部门" width="">
                        </el-table-column>
                        <el-table-column prop="InstallationPosition" label="设备位置" width="">
                        </el-table-column>
                        <el-table-column label="操作" width="">
                            <template slot-scope="scope">
                                <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                    <span style="border-bottom: 1px solid #009BFD;"
                                        @click="choiceEquipment(scope.row)">选择</span>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div>
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                </div>
            </el-dialog>


            <el-dialog title="选择备件" :visible.sync="dialogTableVisibleSpare" top='15vh' width="60%">
                 <div width="60%" style="margin-top:10px">
                    <div class="flex align_center mg_left_50 fn_size14">
                        <div class="">备件型号：</div>
                         <el-input v-model="sparePartModel" class="width_150" clearable></el-input>
                        <!-- <el-select v-model="sparePartModel" placeholder="全部" class="width_150" clearable>
                            <el-option v-for="item in sparePartModelArray" :key="item.value" :label="item.description"
                                :value="item.value">
                            </el-option>
                        </el-select> -->
                        <div class="mg_left_20">备件名称：</div>
                        <el-input v-model="sparePartName" class="width_150" clearable></el-input>
                        <el-button type="primary" size="medium" class="mg_left_10" @click="onQueryLimit">查询
                        </el-button>
                    </div>
                </div>
                <div class="flex flex_column mg_top_15 height_444">
                    <el-form :model="formData" ref="formData" class="height_340">
                        <el-table :data="formData.tableData" border stripe style="width: 100%;" height="100%"
                            @selection-change="handleSelectionChange">
                            <el-table-column type="selection" width="55">
                            </el-table-column>
                            <el-table-column type="index" label="序号" width="70">
                            </el-table-column>
                            <el-table-column prop="sparePartID" label="备件编号" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="sparePartName" label="备件名称" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="sparePartModel" label="备件型号" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="referPrice" label="单价" show-overflow-tooltip>
                            </el-table-column>
                             
                            <el-table-column prop="applyQuan" label="领用数量" width="">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.' + scope.$index + '.applyQuan'"
                                        style="margin-bottom: 0px;">
                                        <el-input v-model="scope.row.applyQuan" placeholder="请输入" type="number"
                                            @input="changeInput(scope.$index)" oninput="if(value<0){value=0}"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="totalPrice" label="总价" width="">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.' + scope.$index + '.totalPrice'"
                                        style="margin-bottom: 0px;">
                                        <el-input v-model="scope.row.totalPrice" readonly></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column prop="Remarks" label="备注" width="">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Remarks'"
                                        style="margin-bottom: 0px;">
                                        <el-input v-model="scope.row.Remarks" placeholder="请输入"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>
                    <el-pagination layout="prev, pager, next" :total="pageQuantitySapre*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPageSpare" @current-change="handleCurrentChangeSpare">
                    </el-pagination>
                    <div class="flex justify_center pdb_10">
                        <el-button @click="dialogTableVisibleSpare = false">取 消</el-button>
                        <el-button type="primary" @click="confirmChoices">确 定</el-button>
                    </div>
                </div>
            </el-dialog>

        </div>
    </div>



    <!-- </div> -->
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        equipmentLedgerOrganizationSelect,
        equipmentLedgerQueryLimit,
    } from "@/api/api_systemConfig";
    import {
        maintainOrderInsert,
        getMaintainExecGroup,
        getMaintainCategory,
        maintainOrderStatusQuery,
        maintainOrderExecGradeQuery,
        getMaintainUrgenLevel,
        maintainOrderExecLevelQuery,
        maintainExecPersonQuery,
        sparePartStockQueryLimit,
        sparePartTypeQuery,
    } from "@/api/api_deviceManagement";
    // 
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                sparePartName: '',
                sparePartModel:'',
                sparePartModel:'',
                value1: '',
                formInline: {},
                value: '',
                options: [],
                formLabelWidth: '110px',
                tableData: [],
                dialogTableVisible: false,
                dialogTableVisibleSpare: false,
                organizationValue: [],
                organization: [],
                standardArr: [],
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    checkStrictly: true //多选任一级
                },
                checkData: {},
                equInfoList: [],
                currentEqu: [],
                currentPage: 1,
                pageSize: 5,
                pageQuantity: 0,
                currentPageSpare: 1,
                pageQuantitySapre: 0,
                permission: {},
                userID: '',
                maintainExecGroup: [],
                maintainUrgenLevel: [],
                planStatusAr: [],
                maintainCategory: [],
                loopUnitQuery: [],
                workOrderStatus: [],
                maintenanceLevel: [],
                maintainLevel: [],
                formSpare: {},
                maintenancePeople: [],
                userName: '',

                spareList: [],
                formData: {
                    tableData: []
                },
                currentSpareData: [],

                load: false,

                pageLoad: true,
                companyType:{},
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
                this.userName = userInfo.userName;
            }
            this.equipmentLedgerOrganizationSelect();

            this.getMaintainExecGroup();
            this.getMaintainUrgenLevel();
            // this.getMaintainPlanStatus();
            this.getMaintainCategory();
            // this.maintainPlanLoopUnitQuery();
            this.maintainOrderStatusQuery();
            this.maintainOrderExecGradeQuery();
            this.maintainOrderExecLevelQuery();
            // this.maintainExecPersonQuery();
            this.sparePartTypeQuery();//备件类型
        },
        methods: {
            // 备件类型下拉选
            async sparePartTypeQuery() {
                var param = {
                    userID: this.userID
                }
                const res = await sparePartTypeQuery(param);
                this.sparePartModelArray = res.data;
            },
             onQueryLimit() {
               
                this.currentPage = 1;
                this.sparePartStockQueryLimit();
                //this.equipmentLedgerQuery();
                
            },

            // 工单状态
            async maintainOrderStatusQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainOrderStatusQuery(param);
                this.workOrderStatus = selectRes.data
            },
            // 评分等级
            async maintainOrderExecGradeQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainOrderExecGradeQuery(param);
                this.maintenanceLevel = selectRes.data
            },
            // 保养组
            async getMaintainExecGroup() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainExecGroup(param);
                this.maintainExecGroup = selectRes.data;
            },
            // 紧急程度
            async getMaintainUrgenLevel() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainUrgenLevel(param);
                this.maintainUrgenLevel = selectRes.data;
            },
            // 保养等级
            async maintainOrderExecLevelQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainOrderExecLevelQuery(param);
                this.maintainLevel = selectRes.data
            },
            // async getMaintainPlanStatus() {
            //     const selectRes = await getMaintainPlanStatus();
            //     this.planStatusAr = selectRes.data;
            // },
            // 保养类型
            async getMaintainCategory() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await getMaintainCategory(param);
                this.maintainCategory = selectRes.data
            },
            // async maintainPlanLoopUnitQuery() {
            //     const selectRes = await maintainPlanLoopUnitQuery();
            //     this.loopUnitQuery = selectRes.rows
            // },
            // 保养人员
            async maintainExecPersonQuery() {
                var param = {
                    userID: this.userID
                } //用户登录时
                const selectRes = await maintainExecPersonQuery(param);
                this.maintenancePeople = selectRes.data
            },
            async sbumit() {
                if (!this.formInline.deviceID) {
                    this.$message({
                        message: '警告！请选择设备',
                        type: 'warning'
                    });
                    return
                }
                // this.$router.go(-1);
                this.formInline.userID = this.userID;
                this.formInline.oprPerson = this.userName;
                delete this.formInline.deviceTypeName;
                this.formInline.SparePartJSON = {
                    array: this.tableData
                }
                if (!this.formInline.machineStop) {
                    delete this.formInline.stopLen;
                }
                console.log(this.formInline)
                const selectRes = await maintainOrderInsert(this.formInline);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.$router.go(-1);
                    this.formInline = {}
                } else {
                    this.$message.error(selectRes.message);
                }
            },
            colsePage() {
                this.$router.go(-1);
            },
            changeDevice() {
                this.equInfoList = [];
                this.pageQuantity = 0;
                this.equipmentLedgerQuery();
                // this.equipmentLedgerOrganizationSelect();
                this.dialogTableVisible = true;
            },
            async equipmentLedgerOrganizationSelect() {
                this.load = true;
                var param = {
                    permission: this.permission
                };
                const selectRes = await equipmentLedgerOrganizationSelect(param);
                this.organization = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.companyType = selectRes.data.organizationPermissionAll.filter(r=>r.Type=="C")[0];//type为c的organization
                this.checkData = this.companyType;
                this.pageLoad = false;
                // this.equipmentLedgerQuery();
            },
            queryDevice() {
                // console.log(this.organizationValue)
                // if (this.organizationValue.join(',') == '') {
                //     this.checkData = this.standardArr[0];
                // }
                // console.log(this.checkData)
                this.currentPage = 1;
                this.equipmentLedgerQuery();
            },
            async equipmentLedgerQuery() {
                this.load = true;
                var param = {
                    organization: this.checkData,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                };
                const selectRes = await equipmentLedgerQueryLimit(param);
                this.equInfoList = selectRes.data;
                this.pageQuantity = selectRes.pageQuantity;
                this.load = false;
            },
            handleCurrentChange(val) {
                this.equipmentLedgerQuery(); //查询table数据的方法
            },
            choiceEquipment(row) {
                this.$confirm('确认选择此设备', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(row);
                    this.formInline.deviceID = row.EquipmentID;
                    this.formInline.deviceName = row.EquipmentName;
                    this.formInline.deviceType = row.EquipmentType;
                    this.formInline.deviceModel = row.EquipmentModel;
                    this.formInline.deviceDepartment = row.WorkshopSection;
                    this.formInline.deviceLocation = row.InstallationPosition;
                    this.formInline.deviceTypeName = row.EquipmentTypeName;
                    // this.formInline
                    // this.currentEqu = [];
                    this.dialogTableVisible = false;
                    // this.currentEqu.push(row);
                    // console.log(this.currentEqu)
                }).catch(() => {});
            },
            handleChangeCascader() {
                // console.log(this.organizationValue)
                if (this.organizationValue.length == 0) {
                    this.checkData = this.companyType;
                    return
                }
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    console.log(authorityadmin)
                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                this.checkData = testData;
                // console.log('checkData', this.checkData);
                // console.log(this.organizationValue)

            },
            // 选择备件--开始
            selectSpare() {
                if (!this.formInline.deviceID) {
                    this.$message({
                        message: '警告哦，请选择设备',
                        type: 'warning'
                    });
                    return
                }
                this.formSpare = {};
                this.sparePartStockQueryLimit();
                this.dialogTableVisibleSpare = true;
            },
            // 备件台账-筛选分页数据
            async sparePartStockQueryLimit() {
                var param = {
                    userID: this.userID,
                    pageNumber: this.currentPageSpare,
                    pageSize: this.pageSize,
                    equipmentID: this.formInline.deviceID
                };
                 if (this.sparePartModel != '') {
                    param.sparePartModel = this.sparePartModel
                }
                if (this.sparePartName != '') {
                    param.sparePartName = this.sparePartName
                }

                const selectRes = await sparePartStockQueryLimit(param);
                // this.spareList = selectRes.rows;
                this.formData.tableData = selectRes.rows;
                this.pageQuantitySapre = selectRes.pageQuantity
            },
            handleCurrentChangeSpare() {
                this.sparePartStockQueryLimit()
            },
            handleSelectionChange(val) {
                console.log(val);
                this.currentSpareData = val;
            },
            confirmChoices() {
                let price = 0;
                let testTableData = [];
                for (var i = 0; i < this.currentSpareData.length; i++) {
                    // console.log(this.currentSpareData[i].applyQuan)
                    if (!this.currentSpareData[i].applyQuan) {
                        this.$message({
                            message: '警告哦，“' + this.currentSpareData[i].sparePartName + '”请填写领用数量',
                            type: 'warning'
                        });
                        return
                    }
                    var testData = {
                        sparePartID: this.currentSpareData[i].sparePartID,
                        sparePartName: this.currentSpareData[i].sparePartName,
                        sparePartModel: this.currentSpareData[i].sparePartModel,
                        unit: this.currentSpareData[i].unit,
                        unitPrice: this.currentSpareData[i].referPrice,
                        applyQuan: this.currentSpareData[i].applyQuan,
                        totalPrice: this.currentSpareData[i].totalPrice,
                        remarks: this.currentSpareData[i].Remarks,
                    }
                    testTableData.push(testData)
                    price += Number(this.currentSpareData[i].totalPrice)
                }
                this.tableData = [];
                this.tableData = testTableData;
                this.formInline.totalPrice = price
                // console.log(this.tableData)
                this.dialogTableVisibleSpare = false;
            },
            // 选择备件--结束
            changeInput(index) {
                // console.log(this.formData)
                // console.log(this.formData.tableData[index])
                this.formData.tableData[index].totalPrice = this.formData.tableData[index].referPrice * this.formData
                    .tableData[index].applyQuan;
            },
            delTable(row) {
                this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(row)
                    this.tableData.splice(row, 1)
                    console.log(this.tableData)
                    let price = 0;
                    for (var i = 0; i < this.tableData.length; i++) {
                        price += this.tableData[i].totalPrice
                    }
                    this.formInline.totalPrice = price;
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
        }

    }
</script>
<style scoped>
    /* .details_box {
        width: 70%;
    }

    .details_box .el-form {
        width: 100%;
    } */

    /* .textarea .el-form-item__content {
        width: 84%
    } */
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }

    // ::v-deep .el-form-item__label {
    //     line-height: 24px;
    // }

    /* // ::v-deep .el-form-item {
    //     margin-bottom: 18px;
    // } */
</style>